import { href, Link } from "react-router";
import { useTranslation } from "react-i18next";
import {
  ITeam,
  TeamAgeGroupLabelEnum,
  TeamGenderLabelEnum,
  TeamLevelLabelEnum,
} from "~/utils/api.interfaces.enums";

export default function ProfileInfo({
  activeTeam,
  hasAdminRights,
}: {
  activeTeam?: ITeam;
  hasAdminRights?: boolean;
}) {
  const { t, ready, i18n } = useTranslation("translation");

  return (
    <>
      <div className="card hidden lg:flex mb-2 lg:mb-4  mt-4 xl:mt-[-50px]">
        <div className="card-body p-0 gap-2 grid grid-cols-5">
          <div className="p-2 xl:p-4 border-r border-gray-100 dark:border-gray-800">
            <h4 className="text-gray-400 text-sm mb-0">
              {t("teamsPage.tabMembers")}
            </h4>
            <h5 className="text-sm xl:text-base">
              {activeTeam?.players.length}
            </h5>
          </div>
          <div className="p-2 xl:p-4  border-r border-gray-100 dark:border-gray-800">
            <h4 className="text-gray-400 text-sm mb-0">
              {t("teamsPage.tabFollowers")}
            </h4>
            <h5 className="text-sm xl:text-base">
              {activeTeam?.followersCount}
            </h5>
          </div>
          <div className="p-2 xl:p-4 border-r border-gray-100 dark:border-gray-800">
            <h4 className="text-gray-400 text-sm mb-0">
              {" "}
              {t("settingsPage.labelTeamLevel")}
            </h4>
            <h5 className="text-sm xl:text-base">
              {activeTeam?.level === "undefined" ? (
                <Link
                  to={href(`/manage-team/settings`) + `#settingsProfile`}
                  className="btn btn-sm btn-link px-0 btn-info no-underline  mr-auto dark:text-secondary"
                >
                  [add info]
                </Link>
              ) : activeTeam?.level ? (
                TeamLevelLabelEnum[activeTeam.level]
              ) : (
                "-"
              )}
            </h5>
          </div>
          <div className="p-2 xl:p-4  border-r border-gray-100 dark:border-gray-800">
            <h4 className="text-gray-400 text-sm mb-0">
              {t("settingsPage.labelTeamAgeGroup")}
            </h4>
            <h5 className="text-sm xl:text-base">
              {activeTeam?.ageGroup === "undefined" ? (
                <Link
                  to={href(`/manage-team/settings`) + `#settingsProfile`}
                  className="btn btn-sm btn-link px-0 btn-info no-underline  mr-auto  dark:text-secondary"
                >
                  [add info]
                </Link>
              ) : (
                TeamAgeGroupLabelEnum[activeTeam?.ageGroup]
              )}
            </h5>
          </div>
          <div className="p-2 xl:p-4 ">
            <h4 className="text-gray-400 text-sm mb-0">
              {" "}
              {t("settingsPage.labelTeamGender")}
            </h4>
            <h5 className="text-sm xl:text-base">
              {activeTeam?.gender === "undefined" ? (
                <Link
                  to={href(`/manage-team/settings`) + `#settingsProfile`}
                  className="btn btn-sm btn-link px-0 btn-info no-underline mr-auto  dark:text-secondary"
                >
                  [add info]
                </Link>
              ) : activeTeam?.gender ? (
                TeamGenderLabelEnum[activeTeam.gender]
              ) : (
                "-"
              )}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
