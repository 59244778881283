import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionFunctionArgs, Form } from "react-router";
import TeamForm from "~/components/blocks/TeamForm";
import WinRatio from "~/components/blocks/WinRatio";

import BlockerModalCheck from "~/components/BlockerModalCheck";
import EngagementRowBlocks from "~/components/blocks/EngagementRowBlocks";
import GoalDifference from "~/components/blocks/GoalDifference";
import GoalsRowBlocks from "~/components/blocks/GoalsRowBlocks";
import { ChangeHeaderImage } from "~/components/form/ChangeHeaderImage";
import { StickySubmitBar } from "~/components/form/StickySubmitBar";
import { useMatchesData } from "~/components/hooks/useMatchesData";
import useMingleRights from "~/components/hooks/useMingleRights";
import ProfileInfo from "~/components/ProfileInfo";
import TeamInfo from "~/components/TeamInfo";
import { i18n } from "~/i18next.server";
import { RootLoaderData } from "~/root";
import { usePagesContext } from "~/routes/layout-pages";
import { FlashMessageSession } from "~/sessions/FlashMessageSession.server";
import { ApiEnum } from "~/utils/api.interfaces.enums";
import { redirectBack } from "~/utils/redirectBack";
import { useFetch } from "~/utils/useFetch.server";

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const flashSession = await FlashMessageSession(request);
  let t = await i18n.getFixedT(request);

  let { _action, ...formValues } = Object.fromEntries(formData);

  switch (_action) {
    case "updateTeamHeader": {
      const body: any = {
        teamId: formValues.teamId as string,
      };
      const imgFile = formValues?.imageFile as File;
      if (imgFile && imgFile?.size > 0) {
        const newTeamHeader = await useFetch({
          request,
          context,
          url: `/Team/uploadheader`,
          imageFile: formValues.imageFile,
          method: "POST",
          apiType: ApiEnum.Mob,
        });

        body.imageUrl = newTeamHeader.fileUrl;
      }

      await useFetch({
        request,
        context,
        url: `/team/header`,
        method: "PUT",
        body,
        apiType: ApiEnum.Mob,
      });

      flashSession.setMessage({
        _action,
        message: t("toastMessages.toastTeamUpdateSuccess"),
        completed: true,
      });
      return redirectBack(request, {
        fallback: "/",
        headers: {
          "Set-Cookie": await flashSession.commit(),
        },
      });
    }
  }

  return null;
};

export default function Index() {
  const { t } = useTranslation("translation");

  const { activeTeam, matches, totals, loggedInUser, ENVIRONMENT } =
    usePagesContext();

  const { hasAdminRights, hasScheduleRights } = useMingleRights({
    activeTeam,
    loggedInUser,
  });

  const { flashMessage } = useMatchesData<RootLoaderData>("root");
  useEffect(() => {
    if (
      flashMessage?.completed &&
      flashMessage._action === "updateTeamHeader"
    ) {
      settingsFormRef.current?.reset();
      setNewImageHeader(null);
      setFormHasChanges(false);
      // setBlockerState("");
    }
  }, [flashMessage]);
  let settingsFormRef = useRef<HTMLFormElement>(null);
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
  const [newImageHeader, setNewImageHeader] = useState<File | null>(null);
  const [teamImageHeader, setTeamImageHeader] = useState<string | undefined>(
    activeTeam?.headerUrl !== ""
      ? activeTeam.headerUrl
      : "/images/team-header-bg.jpg"
  );

  useEffect(() => {
    setTeamImageHeader(
      activeTeam?.headerUrl !== ""
        ? activeTeam.headerUrl
        : "/images/team-header-bg.jpg"
    );
  }, [activeTeam]);

  const [blockerState, setBlockerState] = useState<string>("");
  return (
    <>
      <BlockerModalCheck
        formHasChanges={formHasChanges}
        blockerState={setBlockerState}
      />

      <div
        className={`flex justify-start items-end w-full min-h-[340px] lg:min-h-[530px] mb-0 `}
      >
        <div
          className="absolute top-16 lg:top-0 left-0  h-full w-full  min-h-[340px] lg:min-h-[530px] max-h-[360px] lg:max-h-[540px]"
          style={{
            boxShadow: "inset 0px -153px 71px -47px rgba(0,0,0,1)",
          }}
        >
          <div
            className="absolute w-full bottom-0 h-full"
            style={{
              boxShadow: "inset 0px -153px 71px -47px rgba(0,0,0,1)",
            }}
          ></div>
          <img
            src={
              newImageHeader
                ? URL.createObjectURL(newImageHeader)
                : teamImageHeader
            }
            className="object-cover h-full w-full  min-h-[340px] lg:min-h-[530px] max-h-[540px]"
          />
        </div>
        <TeamInfo
          activeTeam={activeTeam}
          hasAdminRights={hasAdminRights || hasScheduleRights}
          environment={ENVIRONMENT}
        />
      </div>
      <Form
        ref={settingsFormRef}
        method="post"
        encType="multipart/form-data"
        onChange={(e) => {
          setFormHasChanges(true);
        }}
        className={`hidden lg:flex items-center absolute top-0 px-4 m-auto w-full left-0 `}
      >
        <ChangeHeaderImage
          teamHeaderImageChange={(value) => setNewImageHeader(value)}
          teamHeaderChange={(value) => setFormHasChanges(value)}
        />
        <input type="hidden" name="teamId" value={activeTeam?.id} />
        {formHasChanges && (
          <StickySubmitBar
            onDiscardChange={() => {
              setFormHasChanges(false);
              setNewImageHeader(null);
              setTeamImageHeader(
                activeTeam.headerUrl
                  ? activeTeam.headerUrl
                  : "/images/team-header-bg.jpg"
              );
              settingsFormRef.current?.reset();
            }}
            showAnim={blockerState === "unblocked"}
            isBoosted={true}
            formHasChanges={formHasChanges}
            activeTeam={activeTeam}
            submitValue={"updateTeamHeader"}
            hasAdminRights={hasAdminRights || hasScheduleRights}
            label={t("settingsPage.changImageLabel")}
          />
        )}
      </Form>
      <ProfileInfo
        activeTeam={activeTeam}
        hasAdminRights={hasAdminRights || hasScheduleRights}
      />

      <div className="grid md:grid-cols-3 2xl:grid-cols-3 gap-1 md:gap-2 lg:gap-3  xl:gap-4 mt-4 lg:mt-0 mb-2 lg:mb-4">
        <GoalsRowBlocks matches={matches || []} totals={totals} />
      </div>
      <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-1 md:gap-2 lg:gap-3  xl:gap-4 mb-2 lg:mb-4">
        <div className="col-span-2 md:col-span-1 h-full">
          <GoalDifference
            matches={matches || []}
            totals={totals}
            activeTeam={activeTeam}
          />
        </div>
        <div className="col-span-2 md:col-span-1 h-full">
          <WinRatio matches={matches || []} totals={totals} />
        </div>
        <div className="col-span-2 2xl:col-span-1 h-full">
          <TeamForm matches={matches || []} activeTeam={activeTeam} />
        </div>
      </div>
      <div className="grid md:grid-cols-3 2xl:grid-cols-3 gap-1 md:gap-2 lg:gap-3  xl:gap-4">
        <EngagementRowBlocks
          activeTeam={activeTeam}
          matches={matches || []}
          totals={totals}
        />
      </div>
    </>
  );
}
